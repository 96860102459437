import { render, staticRenderFns } from "./AddBrand.vue?vue&type=template&id=77324e92&scoped=true&"
import script from "./AddBrand.vue?vue&type=script&lang=js&"
export * from "./AddBrand.vue?vue&type=script&lang=js&"
import style0 from "./AddBrand.vue?vue&type=style&index=0&id=77324e92&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77324e92",
  null
  
)

export default component.exports