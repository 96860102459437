<template>
  <div class="companySettings">
    <processing-modal :active="processing" v-on:cancel="closeModal()"/>

    <screen-slider-modal
      :sliderMsg="screenSliderMsg"
      v-on:dismiss="dismiss('sliderModal')"
      v-if="sliderModal"
    />
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <transition mode="out-in" name="fade">
          <router-view/>
        </transition>
        <!-- <general-info
          :info="companyInfoToUse"
          :coordVerified="coordVerified"
          :companyBus="companyBus"
          v-on:step-msg="stepComplete"
          v-on:send-to-errors="sendToErrors(...arguments)"
        />
        <verification
          :companyBus="companyBus"
          :info="verificationInfoToUse"
          :coordVerified="coordVerified"
          v-on:step-msg="stepComplete"
          v-on:send-to-errors="sendToErrors(...arguments)"
        />
        <div class="divider"></div>
        <div id="financial-wrapper">
          <financial-info />
        </div>
        <bank-tab
          :companyBus="companyBus"
          v-on:step-msg="stepComplete"
          :info="bankInfoToUse"
          v-on:send-to-errors="sendToErrors(...arguments)"
        />
        <company-page-design
          :companyBus="companyBus"
          v-if="!pageInfo"
          v-on:step-msg="stepComplete"
          v-on:send-to-errors="sendToErrors(...arguments)"
        />
        <div class="company-settings-wrapper buttons">
          <div class="button-wrapper">
            <diyobo-button
              class="saveBtn"
              :txt="'Save Brand Changes'"
              :type="'primary'"
              v-on:click="saveAllInfo"
            />
            <diyobo-button
              class="openmp"
              txt="Open My Page"
              type="primary"
              v-on:click="sendToCompanyPage"
              v-if="saveAll"
            />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.general-title,
.secondary-title {
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  .button-wrapper {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    .saveBtn {
      margin-right: 10px;
    }

    .btn {
      width: 200px;
      padding: 0.75em 0em;
      text-transform: uppercase;
      font-weight: 400;
      border-radius: 0;
      color: #fff;
      background-color: #5cb85c;
      border-color: #4cae4c;
      border-radius: 10px;
      cursor: pointer;
      text-align: center;
      font-size: 14px;
    }

    //end btn
  }

  //end button wrapper
}

@media only screen and (max-width: 600px) {
  // .bank-tab {
  //   .bank-info {
  //     font-size: 14px;
  //   }
  // }
  .buttons {
    margin-top: 30px;

    .button-wrapper {
      // height: ;
      justify-content: space-between;
      flex-wrap: wrap;

      button {
        padding: 10px;
        height: max-content;
      }

      // .openmp {
      //   margin-top: 10px;
      // }
      .saveBtn {
        margin-bottom: 10px;
      }
    }
  }
}

#financial-wrapper {
  margin-top: 10px;
}
</style>

<script>
import DiyoboButton from "@/components/DiyoboButton.vue";
import Vue from "vue";
import verification from "../../components/company/Verification.vue";
import GeneralInfo from "../../components/company/GeneralInfo";
import FinancialInfo from "../../components/company/FinancialInfo";
import bankTab from "../../components/bankTab.vue";
import companyPageDesign from "../../components/company/companyPageDesign.vue";
import ScreenSliderModal from "../../components/modals/ScreenSliderModal.vue";
import {updateSession} from "../../helpers/auth";
import ModalContent from "@/helpers/modals";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import ProcessingModal from "@/components/modals/ProcessingModal.vue";

export default {
  name: "company-settings",
  components: {
    ProcessingModal,
    GeneralInfo,
    FinancialInfo,
    bankTab,
    companyPageDesign,
    ScreenSliderModal,
    verification,
    DiyoboButton,
    Breadcrumbs
  },
  computed: {
    userEmail() {
      return this.$store.state.user.email;
    }
  },
  head() {
    return {
      title: "Brand Settings"
    };
  },
  data() {
    return {
      breadcrumbs: [
        ["Brand Dashboard", "/brands"],
        ["Add Brand", null]
      ],
      screenSliderMsg: "",
      sliderModal: false,
      pageInfo: false,
      processing: false,
      companyInfo: false,
      companyData: false,
      companyInfoToUse: {},
      bankInfoToUse: {},
      pageInfoToUse: {},
      bankInfo: false,
      subdomainLink: "",
      coordVerified: false,
      verificationInfo: false,
      verificationInfoToUse: {},
      companyBus: new Vue(),
      infoErrorsToDisplay: [],
      triggerUpdate: false,
      saveAll: false
    };
  },
  watch: {
    sliderModal(val) {
      setTimeout(() => {
        if (this.sliderModal) this.sliderModal = false;
      }, 7000);
    },
    companyInfo(val) {
      this.companyInfoToUse = val;
      this.companyData = true;
    },
    bankInfo(val) {
      this.bankInfoToUse = val;
    },
    pageInfo(val) {
      this.pageInfoToUse = val;
      if (val.subdomain) this.subdomainLink = val.subdomain;
    },
    verificationInfo(val) {
      this.verificationInfoToUse = val;
    },
    triggerUpdate(val) {
      if (val == true) {
        if (this.infoErrorsToDisplay.length > 0) {
          window.scrollTo(0, 222);

          this.triggerUpdate = false;
          this.infoErrorsToDisplay = [];
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.processing = false;
    },
    dismiss(val) {
      this[val] = false;
    },
    stepComplete(key, objToInject) {
      if (objToInject) this[key] = objToInject;
      else this[key] = objToInject;

      this.displayPopMsg();
    },
    sendToCompanyPage() {
      if (this.pageInfoToUse && this.pageInfoToUse.subdomain) {
        switch (process.env.VUE_APP_ENVIRONMENT) {
          case "development":
            window.open("http://localhost:3200/brand-page", "_blank");
            break;
          case "staging":
            window.open(
              "https://planners.staging.incredevent.com/brand-page",
              "_blank"
            );
            break;
          default:
            window.open("https://planners.incredevent.com/brand-page", "_blank");
            break;
        }
      }
    },
    displayPopMsg() {
      if (this.companyInfo && this.pageInfo) {
        this.processing = true;
        this.saveAll = true;
        this.$store.commit("updateUserProperty", {
          property: "plannerBrands",
          value: 1
        });

        if (!this.$store.state.user.coordinator) {
          this.$store.state.user.coordinator = true;
        }
        updateSession(this);

        setTimeout(() => {
          this.processing = false;
          this.$store.state.bus.$emit(
            "alert",
            this.coordVerified
              ? ModalContent.companySaved
              : ModalContent.companySavedUnverified
          );
        }, 2000);

        // this.$router.push("/brand-page");
      }
    },
    saveAllInfo() {
      this.companyBus.$emit("save-company-info");
    },
    sendToErrors(type, error) {
      let eleToRemove;
      if (!error) {
        eleToRemove = this.infoErrorsToDisplay.find(ele => ele == type);
      }
      this.infoErrorsToDisplay.filter(ele => {
        if (ele != eleToRemove) return ele;
      });
      if (error) {
        this.infoErrorsToDisplay.push(type);
        this.triggerUpdate = true;
      }
    }
  },
  mounted() {
    //check verified planner
    if (!this.$store.state.user.coordVerified) {
        this.$router.push('/id-protection')
    }
  },
  created() {
    this.$store.commit("setTitle", "Add Brand");
  }
};
</script>
